import { ComponentType } from "react"
import { subtliteVideo } from "./ApiHeroku.tsx"
import React, { useState, useEffect } from "react"
import { createContext, useContext, useReducer, useRef } from "react"

type MyContextType = {
    // Defina as propriedades e métodos que deseja incluir no contexto
}

// Contexto criado
const MyContext = createContext<MyContextType | undefined>(undefined)

//coloque essa função na pagina inteira
export function mainContext(Component): ComponentType {
    return (props) => {
        //aqui você coloca todos os dados que seram enviados ao backend
        const [video, setVideo] = useState()
        const [targetLanguage, setTargetLanguage] = useState("de")
        const [fontFamily, setFontFamily] = useState("Arial")
        const [fontSize, setFontSize] = useState(15)
        const [fontWeight, setFontWeight] = useState("normal")
        const [fontColor, setFontColor] = useState("#FFFF00")
        //adicione um campo para receber a resposta, no caso é o link
        const [link, setLink] = useState()
        return (
            <MyContext.Provider
                //coloque todas as variaveis criadas acima no value para as outras funções terem acesso a elas
                value={{
                    video,
                    setVideo,
                    targetLanguage,
                    setTargetLanguage,
                    fontFamily,
                    setFontFamily,
                    fontSize,
                    setFontSize,
                    fontWeight,
                    setFontWeight,
                    fontColor,
                    setFontColor,
                    link,
                    setLink,
                }}
            >
                {" "}
                <Component {...props} />{" "}
            </MyContext.Provider>
        )
    }
}

//aqui esta criado a função que mostrara o link de download
export function link(Component): ComponentType {
    return (props) => {
        //pega o valor de link
        const { link } = useContext(MyContext) || {
            //para evitar erros na interface do framer coloque os valores importados aqui e iguale com ""
            link: "",
        }
        //verifica se tem o link
        if (link) {
            //se tiver ele coloca o link para download
            return (
                <Component {...props}>
                    <a href={link} target="_blank">
                        Baixar video
                    </a>
                </Component>
            )
        } else {
            //se não tiver ele retorna o componente
            return <Component {...props} />
        }
    }
}

//apartir daqui você tem que criar um override para cada um dos elementos que vai pro backend

//input da font
export function fontFamily(Component): ComponentType {
    return (props) => {
        //sempre importe a variavel e o set correspondente a ela quando é uma função de input
        const { fontFamily, setFontFamily } = useContext(MyContext) || {
            //para evitar erros na interface do framer coloque os valores importados aqui e iguale com ""
            fontFamily: "",
            setFontFamily: "",
        }
        const handleChange = (e) => {
            //coloque o set da variavel aqui
            setFontFamily(e.target.value)
        }
        return (
            <Component {...props}>
                <select
                    id="fontWeight"
                    value={fontFamily}
                    onChange={handleChange}
                    required
                    style={styleInputSelect}
                >
                    <option value="Arial">Arial</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Courier">Courier</option>
                </select>
            </Component>
        )
    }
}

//input da linguagem
export function targetLanguage(Component): ComponentType {
    return (props) => {
        //sempre importe a variavel e o set correspondente a ela
        const { targetLanguage, setTargetLanguage } = useContext(MyContext) || {
            //para evitar erros na interface do framer coloque os valores importados aqui e iguale com ""
            targetLanguage: "",
            setTargetLanguage: "",
        }
        const handleChange = (e) => {
            //coloque o set da variavel aqui
            setTargetLanguage(e.target.value)
        }
        return (
            <Component {...props}>
                <select
                    id="fontWeight"
                    value={targetLanguage}
                    onChange={handleChange}
                    required
                    style={styleInputSelect}
                >
                    <option value="de">German</option>
                    <option value="es">Spanish</option>
                    <option value="pt">Portuguese</option>
                    <option value="fr">French</option>
                    <option value="fr">Mandarim</option>
                </select>
            </Component>
        )
    }
}

export function fontWeight(Component): ComponentType {
    return (props) => {
        //sempre importe a variavel e o set correspondente a ela
        const { fontWeight, setFontWeight } = useContext(MyContext) || {
            //para evitar erros na interface do framer coloque os valores importados aqui e iguale com ""
            fontWeight: "",
            setFontWeight: "",
        }
        const handleChange = (e) => {
            //coloque o set da variavel aqui
            setFontWeight(e.target.value)
        }
        return (
            <Component {...props}>
                <select
                    id="fontWeight"
                    value={fontWeight}
                    onChange={handleChange}
                    required
                    style={styleInputSelect}
                >
                    <option value="normal">Normal</option>
                    <option value="bold">Bold</option>
                </select>
            </Component>
        )
    }
}

export function fontSize(Component): ComponentType {
    return (props) => {
        const { fontSize, setFontSize } = useContext(MyContext) || {
            fontSize: "",
            setFontSize: "",
        }
        const handleChange = (e) => {
            setFontSize(e.target.value)
        }
        return (
            <Component {...props}>
                <input
                    style={styleInput}
                    type="number"
                    value={fontSize}
                    onChange={handleChange}
                    placeholder={"Digite sua duvida aqui"}
                />
            </Component>
        )
    }
}

export function video(Component): ComponentType {
    return (props) => {
        const { video, setVideo } = useContext(MyContext) || {
            video: "",
            setVideo: "",
        }
        const handleChange = (e) => {
            setVideo(e.target.files[0])
        }
        return (
            //não é possivel estilizar o btn de upload de arquivo então vamos deixar ele oculto
            //envolvendo o coponente em uma label que esta linkada ao input de arquivo faz com que ele ganhe a mesma funcionalidade do btn de upload
            //um label é possivel estilizar
            //a estilização podera ser feita la pela interface
            <label htmlFor="videoFile" style={styleInput}>
                <Component {...props}>
                    <input
                        type="file"
                        id="videoFile"
                        name="videoFile"
                        style={{ display: "none" }}
                        onChange={handleChange}
                        accept="video/*"
                        required
                    />
                    <h2>{video && video.name}</h2>
                </Component>
            </label>
        )
    }
}

export function fontColor(Component): ComponentType {
    return (props) => {
        const { fontColor, setFontColor } = useContext(MyContext) || {
            fontColor: "",
            setInput: "",
        }
        const handleChange = (e) => {
            setFontColor(e.target.value)
        }
        return (
            <Component {...props}>
                <input
                    style={styleInput}
                    type="color"
                    value={fontColor}
                    onChange={handleChange}
                    placeholder={"Digite sua duvida aqui"}
                />
            </Component>
        )
    }
}

export function button(Component): ComponentType {
    return (props) => {
        const {
            video,
            targetLanguage,
            fontFamily,
            fontSize,
            fontWeight,
            fontColor,
            setLink,
        } = useContext(MyContext) || {
            input: "",
            setInput: "",
            value: "",
            setValue: "",
        }
        const handleClick = async () => {
            // gera o formulario para podr ser enviado
            const formData = new FormData()
            formData.append("video", video)
            formData.append("targetLanguage", targetLanguage)
            formData.append("fontFamily", fontFamily)
            formData.append("fontSize", fontSize)
            formData.append("fontWeight", fontWeight)
            formData.append("fontColor", fontColor)

            //envia os dados la pra o outro arquivo fazer o fetch
            const data = await subtliteVideo(formData)

            setLink(data)
        }
        return <Component onClick={handleClick} {...props} />
    }
}
const styleInput = {
    width: "100%",
    height: "100%",
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
}
const styleInputSelect = {
    width: "100%",
    height: "100%",
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
}
